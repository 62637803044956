import React from "react"

import Layout from 'components/Layout/Layout';
import Error from 'components/Error/Error';
import SEO from 'components/seo';

const CancelPage = () => (
  <Layout displayProgressBar={false}>
    <SEO title="Cancel" />
    <Error cancel={true} />
  </Layout>
)

export default CancelPage
